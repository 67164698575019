<header>
    <h1>Privacy Policy</h1>
</header>

<main>
    <h2>Introduction</h2>
    <p>Welcome to DreamMenders Marketing Agency. We take your privacy seriously and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website.</p>

    <h2>What Information We Collect</h2>
    <p>We may collect the following types of personal information:</p>
    <ul>
        <li>Your name, email address, phone number, and other contact information when you fill out forms on our website.</li>
        <li>Information about your visit, including IP address, browser type, referral source, and pages visited on our site.</li>
        <li>Any other information that you voluntarily provide to us.</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
        <li>To respond to your inquiries and provide the services you request.</li>
        <li>To improve the functionality and performance of our website.</li>
        <li>To send you marketing communications, newsletters, and updates, if you have opted in to receive them.</li>
        <li>To comply with legal requirements and protect our legal rights.</li>
    </ul>

    <h2>Cookies Policy</h2>
    <p>Our website uses cookies to improve your experience. Cookies are small files stored on your device that help us analyze website traffic and customize content. You can control cookie settings in your browser, and you can choose to accept or decline cookies.</p>

    <h2>Third-Party Services</h2>
    <p>We may use third-party services, such as Google Analytics, to help us understand website traffic and usage. These services may collect information about your interaction with our website. We do not control these third-party services, but we ensure they comply with data protection regulations.</p>

    <h2>Data Security</h2>
    <p>We are committed to securing your personal information. We use a variety of security technologies and procedures to help protect your data from unauthorized access, use, or disclosure.</p>

    <h2>Your Rights</h2>
    <p>You have the following rights regarding your personal information:</p>
    <ul>
        <li>Right to access the personal data we hold about you.</li>
        <li>Right to request corrections to any inaccurate or incomplete data.</li>
        <li>Right to request the deletion of your personal data.</li>
        <li>Right to withdraw consent for data processing, where applicable.</li>
    </ul>

    <h2>Changes to This Policy</h2>
    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. Any updates will be posted on this page with the "Last Updated" date.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at:</p>
    <p>Email: <a href="mailto:info@dreammenders.com">info&#64;dreammenders.com</a></p>
    <p>Phone: +91-9494-50-5790</p>
</main>